import React from 'react';
import { useSpring, animated } from 'react-spring';

import logo from '../assets/images/ERA_GRIS.png';
import { Link } from 'gatsby';
import MediaQuery from 'react-responsive';
import Gallery from './Gallery';

const SplashHome = ({ data }) => {
 /* const ref = useRef([]);
  const [items, set] = useState([]);*/

  const spring1 = useSpring({
    config: { duration: 600 },
    from: {
      opacity: 1,
      height: 300,
      innerHeight: 280,
      //transform: 'perspective(600px) rotateX(0deg)'
      transform: 'scale(1,1)'
    },
  to: [{opacity: 0, transform: 'scale(0.5,0.5)'}, {display: 'none' } ],
  delay: 200
});

const spring2 = useSpring({from: {
  opacity: 0,
  height: 300,
  innerHeight: 280,
  //transform: 'perspective(600px) rotateX(0deg)'
  transform: 'scale(0.5,0.5)'
},
to: {opacity: 1, transform: 'scale(1,1)' },
delay: 1200
});

  const Home = () => (
    <header className="major">
      <img
        className="major-logo"
        alt="logo era"
        src={`${logo}`}
        style={{
          zIndex: 100
        }}
      />{' '}
    </header>
  );


  const props = useSpring({
    to: { opacity:  1, color: '#fffffff2' },
    from: { opacity: 0 },
    delay: 1000
  });


  return (
    <section
      id="header2"
      className="grid-cointainer"
      style={{
        marginTop:'-10%',
        marginBottom: '15%',
        zIndex: 1
      }}
    >
      <div className="container item-a">
        <animated.div style={{
          ...spring1,
            }} >
          <Home />
        </animated.div>
        <animated.div style={{...spring2}}>
          <Link to="/Proyectos" className="title">
            {' '}
            <p> CREAMOS ARQUITECTURA DE HÁBITOS </p>{' '}
          </Link>
        </animated.div>
      </div>

        <MediaQuery maxDeviceWidth={991} >
          <Gallery
            classCss={'top-mid'}
            projects={ data }
            title="Últimos Proyectos"
            description=""
            width={68}
          />
        </MediaQuery>
        
        <MediaQuery minDeviceWidth={992 } device={{ deviceWidth: 1600 }}>
          <div style={{ ...props}} className="item-b">            
            <Gallery
              /*  classCss={'top-mid'}*/
                projects={ data }
                title="Últimos Proyectos"
                description=""
                large={true}
              />   
          </div>        
        </MediaQuery>
        
     
    </section>
  );
}
//  <div className="gap"></div>   
export default React.memo(SplashHome);

