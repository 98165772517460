import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Splash from '../components/SplashHome';
import Container from '../components/Container';


const IndexPage = ({data}) => {
  const projects = data.allProjectDataJson.edges;
    return(
    <Layout  >
      <Container  >
        <div className="">
          <Splash data={projects}/>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage;


export const projectFragment = graphql`
  fragment projectFragment on ProjectDataJson {
    id
    seccionTitle
    description
    cover
    album
    category
  }
`;

export const projectsQuery = graphql`
  query {
    allProjectDataJson(limit: 10) {
      edges {
        node {
          ...projectFragment
        }
      }
    }
  }
`;
